import flatpickr from "flatpickr";
import "flatpickr/dist/flatpickr.css";
import { French } from "flatpickr/dist/l10n/fr.js"

function initRangeFlatpickr(selector) {
  document.addEventListener("DOMContentLoaded", function () {
    const inputs = document.querySelectorAll(selector)
    if (inputs.length > 0) {
      inputs.forEach((input) => {
        flatpickr(input, {
          allowInput: true,
          locale: French,
          mode: "range",
          dateFormat: "Y-m-d",
          defaultDate: [input.dataset.dateStart, input.dataset.dateEnd]
        });
      })
    }
  });
}

function initDateFlatpickr(selector) {
  document.addEventListener("DOMContentLoaded", function () {
    const inputs = document.querySelectorAll(selector)
    if (inputs.length > 0) {
      inputs.forEach((input) => {
        flatpickr(input, {
          allowInput: true,
          locale: French,
          dateFormat: "Y-m-d",
          defaultDate: input.dataset.defaultDate
        });
      })
    }
  });
}

export { initRangeFlatpickr, initDateFlatpickr };
